.card {
  margin-right: 5px;
  padding: 10px;
    
    height:40px;
  background: #a855f7;
  text-align: center;
  position: relative;
  /* overflow: hidden; Ensures pseudo-elements don't overflow the card */
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.card::after {
  content: "";
  position: absolute;
  height: 120%;
  width: 105%;
  background-image: conic-gradient(
    from var(--angle),
    transparent 70%,
    rgb(199, 195, 195),
    white
  );
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  padding: 3px;
  animation: spin 3s linear infinite;
}

.card::before {
  filter: blur(1.5rem);
  opacity: 0.5;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

@media (max-width: 640px) {
  .card::after {
    width: 120%;
    height: 120%; /* Ensure consistent scaling */
  }
}

@media (max-width: 768px) {
  .card::after {
    width: 118%;
    height: 118%; /* Ensure consistent scaling */
  }
}

.returnCard {
  position: relative; /* Ensure pseudo-elements are positioned relative to the card */
}

.returnCard::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: white;
  transition: width 1s ease-in-out; /* Smooth transition */
}

.returnCard:hover::before {
  width: 100%;
}





.apply-btn {
  /* font-family: "Noto Sans JP", sans-serif; */
  background-color: #050505;
  border: none;
  padding: 7px 34px;
  color: white;
  cursor: pointer;
  border: 1px solid #c26bfc;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  /* Added position for pseudo-elements */
  transition: 0.4s;
}

.apply-btn:hover {
  background-color: #a855f7;
  color: #fff;
}

.apply-btn:before {
  content: '';
  background: linear-gradient(45deg, #FF4400, #f2ff00, #00d9ff, #FF5500, #ff00ff);

  position: absolute;
  top: -1px;
  left: -1px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: glowing 20s linear infinite;
  /* Glowing effect applied permanently */
  opacity: 1;
  /* Keep the glow visible all the time */
  border-radius: 10px;
}

.apply-btn:active {
  color: #d94de6;
}

.apply-btn:active:after {
  background: transparent;
}

.apply-btn:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #e285e2;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}